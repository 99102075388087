import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Switch, Route, Redirect } from 'react-router-dom';

import SignInPage from 'containers/SignInPage/Loadable';
import SignUpPage from 'containers/SignUpPage/Loadable';
import DashboardPage from 'containers/DashboardPage/Loadable';
import { AuthContext, withAuth } from '../../context/AuthContext';
import { getTenantDetail } from '../../utils/tenant';
// max-width: calc(768px + 16px * 2);
const AppWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  height: 100hv;
  padding: 0 16px;
  flex-direction: column;
`;

// Updated to use context via HOC
const AuthorizationRedirector = withAuth(({ component: Component, auth, ...rest }) => {
  return (
    <Route
      {...rest}
      render={
        (props) => auth.validAccessToken === true
          ? <Component {...props} />
          : <Redirect to={{ pathname: '/signin', state: { from: props.location } }} />}
    />
  );
});


const UnauthorizedRedirector = withAuth(({ component: Component, auth, ...rest }) => {
  return (
    <Route
      {...rest}
      render={
        (props) => auth.validAccessToken === false
          ? <Component {...props} />
          : <Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} />}
    />
  );
});

const { title, favIcon } = getTenantDetail();
export default class App extends Component {
  render() {
    return (
      <AuthContext>
        <AppWrapper>
          <Helmet
            titleTemplate={`%s - ${title}`}
            defaultTitle={title}
            
          >
            <link rel="icon" href={favIcon} />
            <meta name="description" content="A React.js Boilerplate application" />
          </Helmet>
          {/* <Header /> */}
          <Switch>
            <Route exact path="/" component={() => <Redirect to={{ pathname: '/dashboard' }} />} />
            <UnauthorizedRedirector path="/signup" component={SignUpPage} />
            <UnauthorizedRedirector path="/signin" component={SignInPage} />
            <AuthorizationRedirector path="/dashboard" component={DashboardPage} />
          </Switch>
        </AppWrapper>
      </AuthContext>
    );
  }
}
