import aprilLogo from 'components/Dashboard/ssApril@3x.png';

export const getTenantId = () => {

    const tenantId = process.env.REACT_APP_TENANT_ID;
    if (tenantId) {
        return tenantId;
    }
    const domain = window.location.hostname;
    if (domain.startsWith('olive')) {
        return 'olive';
    }
    return 'april';
}

export const getTenantDetail = () => {
    const tenantId = getTenantId();
    const oliveDetails = {
        title: 'Olive Jordan',
        logo: aprilLogo,
        favIcon: '/favicon-olive.ico',
        className: 'olive',
    }
    const aprilDetails = {
        title: 'April Accessories',
        logo: aprilLogo,
        favIcon: '/favicon.ico',
        className: 'april',
    }
    if (tenantId === 'olive') {
        return oliveDetails;
    }
    return aprilDetails;
}