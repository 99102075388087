import React from 'react';
import PropTypes from 'prop-types';
import Api from '../utils/Api';

export const AuthContext = React.createClass({
    childContextTypes: {
        auth: PropTypes.shape({
            validAccessToken: PropTypes.bool,
            refreshToken: PropTypes.string,
            login: PropTypes.func,
            logout: PropTypes.func,
        }),
    },

    getInitialState() {
        return {
            validAccessToken: null,
            refreshToken: localStorage.getItem('refreshToken'),
        };
    },

    getChildContext() {
        return {
            auth: {
                validAccessToken: this.state.validAccessToken,
                refreshToken: this.state.refreshToken,
                login: this.login,
                logout: this.logout,
            },
        };
    },

    componentDidMount() {
        this.refreshAuthToken();

        // Set interval to refresh token
        this.refreshInterval = setInterval(() => {
            this.refreshAuthToken();
        }, 115000);
    },

    componentWillUnmount() {
        // Clear interval when component unmounts
        if (this.refreshInterval) {
            clearInterval(this.refreshInterval);
        }
    },

    refreshAuthToken() {
        const api = Api.create();

        if (this.state.refreshToken) {
            api.refreshToken()
                .then((res) => {
                    if (res.ok) {
                        this.login(res.data);
                    } else {
                        this.logout();
                    }
                })
                .catch(() => {
                    this.logout();
                });
        } else {
            this.logout();
        }
    },

    logout() {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('tokenGetIn');
        localStorage.removeItem('expiresIn');
        localStorage.removeItem('userId');
        localStorage.removeItem('adminId');
        this.setState({
            validAccessToken: false,
            refreshToken: null
        });
    },

    login(data) {
        localStorage.setItem('accessToken', data.accessToken);
        localStorage.setItem('refreshToken', data.refreshToken);
        localStorage.setItem('tokenGetIn', new Date());
        localStorage.setItem('expiresIn', data.expiresIn);
        localStorage.setItem('userId', data.userId);
        localStorage.setItem('adminId', data.adminId);

        this.setState({
            validAccessToken: true,
            refreshToken: data.refreshToken
        });
    },
    render() {
        // Only render children when auth state is determined
        return this.state.validAccessToken !== null ? this.props.children : null;
    }
});

// Higher Order Component for consuming the context
export function withAuth(Component) {
    const WithAuth = React.createClass({
        contextTypes: {
            auth: PropTypes.shape({
                validAccessToken: PropTypes.bool,
                refreshToken: PropTypes.string,
                refreshAuthToken: PropTypes.func,
                logout: PropTypes.func,
            }),
        },

        render() {
            return <Component {...this.props} auth={this.context.auth} />;
        }
    });

    return WithAuth;
}

export default AuthContext; 